import { APP_VARIABLES, DATE_FORMAT, NOTIFICATION_POSITION, NOTIFICATION_TYPE } from '@constants/app-constants';
import { hrSubscription, subMenus } from '@constants/';
import { message, notification } from 'antd';

import { FILE_CONSTANTS } from '@constants';
import { appConstant } from '@constants';
import { appMenus } from '@constants/';
import { appMenusRegEx } from '@constants/';
import { createBrowserHistory } from 'history';
import { debounce } from 'lodash';
import { images } from 'src/assets/img';
import moment from 'moment';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import callCleverTapEvent from './cleverTap';
import userManager from 'src/config/user-manager';

// accessing and adding localstorage variables

export const history = createBrowserHistory ? createBrowserHistory() : [];
export const getSessionVariable = (key) => JSON.parse(sessionStorage.getItem(key));
export const setSessionVariable = (key, value) => sessionStorage.setItem(key, JSON.stringify(value));
export const removeSessionVariable = (key) => sessionStorage.removeItem(key);

export const getLocalvariable = (key) => localStorage.getItem(key);
export const setLocalvariable = (key, value) => localStorage.setItem(key, JSON.stringify(value));
export const removeLocalvariable = (key) => localStorage.removeItem(key);

export const dummyRequest = ({ file, onSuccess }) => {
	setTimeout(() => {
		onSuccess('ok');
	}, 0);
};

export const asyncLocalStorage = {
	setItem: function (key, value) {
		return Promise.resolve().then(function () {
			localStorage.setItem(key, value);
		});
	},
	getItem: function (key) {
		return Promise.resolve().then(function () {
			return localStorage.getItem(key);
		});
	},
	removeItem: function (key) {
		return Promise.resolve().then(function () {
			return localStorage.removeItem(key);
		});
	},
};
export const syncLocalStorage = {
	setItem: (key, value) => {
		localStorage.setItem(key, value);
	},
	getItem: (key) => {
		return localStorage.getItem(key);
	},
	removeItem: (key) => {
		return localStorage.removeItem(key);
	},
};

export const propsAreEqual = (prevState, nextState) => {
	const previousData = JSON.stringify(prevState);
	const nextData = JSON.stringify(nextState);
	return previousData === nextData;
};
export const devlog = (log) => {
	if (process.env.NODE_ENV !== 'production') {
	}
};
export const getAuthKey = () => {
	return (JSON.parse(localStorage.getItem('authKey')) && atob(JSON.parse(localStorage.getItem('authKey')))) || null;
};
export const getAuthToken = () => {
	return (JSON.parse(localStorage.getItem('authToken')) && atob(JSON.parse(localStorage.getItem('authToken')))) || null;
};
export const getKeyAndToken = () => {
	const authKey = (JSON.parse(localStorage.getItem('authKey')) && atob(JSON.parse(localStorage.getItem('authKey')))) || null;
	const authToken = (JSON.parse(localStorage.getItem('authToken')) && atob(JSON.parse(localStorage.getItem('authToken')))) || null;
	return { authKey, authToken };
};

export const isAuth = (permissions, userRole) => {
	// return permissions.includes(userRole);
	return permissions.indexOf(userRole) >= 0;
};
export const handleErrorMessage = (err) =>
	err.toString(err) === 'Error: Request failed with status code 400' ? appConstant.userNotExist : 'Something went wrong';

export const checkValue = (value) => {
	if (typeof value !== 'undefined' && value) {
		//deal with value'
		return value;
	}
};
export const checkByNested = (obj, key) => {
	return key.split('.').reduce(function (o, x) {
		return typeof o == 'undefined' || o === null ? o : o[x];
	}, obj);
};
export const activeMenu = (menuRoutes, path) => menuRoutes.findIndex((menu) => menu.route === path);
export const replaceNullAndSpace = (value) => {
	if (!value || value === '') return '-';
	return value;
};
export const setStartDate = (value) => {
	var date = new Date();
	switch (value) {
		case 'LAST':
			date = new Date().getDate();
			return new Date().setDate(date - 7);
		case 'LASTFOUR':
			date = new Date().getDate();
			return new Date().setDate(date - 4);
		case 'YEST':
			date = new Date().getDate();
			return new Date().setDate(date - 1);
		case 'THIS_WEEK':
			var d = new Date();
			var day = d.getDay(),
				diff = d.getDate() - day + (day === 0 ? -6 : 1);
			return new Date(d.setDate(diff));
		case 'THIS_MONTH':
			date = new Date();
			return new Date(date.getFullYear(), date.getMonth(), 1);
		default:
			break;
	}
};

export const getMonthLabelFromNumber = (monthInNumber, monthFormat = 'MMM') => moment().month(monthInNumber).format(monthFormat);
export const getMonthNumberFromName = (monthInLabel) => moment().month(monthInLabel).format('M');
export const getCurrentDate = (format) => moment().format(format);

export const disableFutureDate = (disableFutureDate) => {
	return disableFutureDate >= new Date();
};

export const constructTrackingStrings = (sourceValues) =>
	Object.entries(sourceValues)
		.map(([key, value]) => {
			return value && `&${value}=[${value}]`;
		})
		.join('');

export const getYears = (futureYears = 10, pastYears = 1) => {
	const years = [];
	const dateStart = moment().subtract(pastYears, 'years');
	const dateEnd = moment().add(futureYears + 1, 'years');
	while (dateEnd.diff(dateStart, 'years') > 0) {
		years.push(dateStart.format('YYYY'));
		dateStart.add(1, 'year');
	}
	return years;
};

export const getWpsYears = (futureYears = 1, pastYears = 1) => {
	const years = [];
	const dateStart = moment().subtract(pastYears, 'years');
	const dateEnd = moment().add(futureYears + 1, 'years');
	while (dateEnd.diff(dateStart, 'years') > 0) {
		years.push(dateStart.format('YYYY'));
		dateStart.add(1, 'year');
	}
	return years;
};

export function getMonths(month, year) {
	var ar = [];
	var start = moment(year + '-' + month, 'YYYY-MMM');
	for (var end = moment(start).add(1, 'month'); start.isBefore(end); start.add(1, 'day')) {
		// ar.push(start.format('D-ddd'));
		ar.push(start.format('D'));
	}
	return ar;
}
export function getMonth(month, year) {
	var ar = [];
	var start = moment(year + '-' + month, 'YYYY-MMM');
	for (var end = moment(start).add(1, 'month'); start.isBefore(end) && start.diff(moment()) < 0; start.add(1, 'day')) {
		//for (var end = moment(start).add(1, 'month'); start.isBefore(end); start.add(1, 'day')) {
		// ar.push(start.format('D-ddd'));
		ar.push(start.format('D'));
	}
	return ar;
}

export const checkHrSubscription = (subscriptionProds = []) => {
	return subscriptionProds !== '' && subscriptionProds?.filter((subProd) => subProd.name === hrSubscription.MANAGE_HR).length > 0;
};

export const checkWithClaims = (userClaims = [], claim) => userClaims.find((claimData) => claimData.value === claim);

export const checkWithHRClaims = (userClaims = [], claim) => userClaims.find((claimData) => claimData.value === claim);
// export const checkWithALLClaims = (claims = [], userClaims = []) => claims.every((claim) => userClaims.includes(claim));
export const checkWithALLClaims = (claims = [], userClaims = []) => claims.every((claim) => userClaims.indexOf(claim) >= 0);

export const checkAnyClaims = (claims = [], userClaims = []) => claims?.find((claim) => userClaims?.includes(claim?.value));

export function viewDateFormat(date) {
	let formattedDate = moment(date, 'YYYY-MM-DDThh:mm:ss').format('DD/MM/YYYY');
	return formattedDate !== 'Invalid date' ? formattedDate : '';
}
export function setDateFormat(date) {
	let formattedDate = moment(date, 'YYYY-MM-DDThh:mm:ss').format('YYYY-MM-DD');
	return formattedDate !== 'Invalid date' ? formattedDate : '';
}
export function getDateFormat(date) {
	let dateObj = new Date(date);
	const dateTime = moment(dateObj).format('YYYY-MM-DDThh:mm:ss');
	return dateTime !== 'Invalid date' ? dateTime : '';
}

export function groupByKey(array, key) {
	return array.reduce((hash, obj) => {
		if (obj[key] === undefined) return hash;
		return Object.assign(hash, { [obj[key]]: (hash[obj[key]] || []).concat(obj) });
	}, {});
}

export const getOpenedMenu = (pathName) => {
	let url = null;
	url = Object.assign(
		{},
		...appMenus.filter((obj) => {
			// if (obj.menus.includes(pathName)) {
			if (obj.menus.indexOf(pathName) >= 0) {
				return obj.url;
			}
		})
	).url;
	if (!url) {
		url = Object.assign(
			{},
			...appMenusRegEx.filter((obj) => {
				if (pathName.match(obj.regEx)) {
					return obj.url;
				}
			})
		).url;
	}
	return url;
};
export const getOpenedSubMenu = (pathName) => {
	let subMenu = null;
	subMenu = Object.assign(
		{},
		...subMenus.filter((obj) => {
			// if (obj.menus.includes(pathName)) {
			if (obj.menus.indexOf(pathName) >= 0) {
				return obj.subMenu;
			}
		})
	).subMenu;
	return subMenu || [];
};

export const numberWithCommas = (number) => {
	return number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export function isInt(n) {
	return Number(n) === n && n % 1 === 0;
}

export function isFloat(n) {
	return Number(n) === n && n % 1 !== 0;
}

export const changeNumbertoCurrency = (number) => {
	let amount = number.toLocaleString('az-AE', {
		style: 'currency',
		currency: 'AED',
		currencyDisplay: 'code',
		maximumFractionDigits: 2,
		minimumFractionDigits: 2,
	});
	let splitAmount = amount.toString().split('.');
	let currency = splitAmount[0];
	return currency;
};

export const changeNumbertoWholeCurrency = (number) => {
	let amount = number.toLocaleString('az-AE', {
		style: 'currency',
		currency: 'AED',
		currencyDisplay: 'code',
		maximumFractionDigits: 2,
		minimumFractionDigits: 2,
	});
	let currency = amount;
	return currency;
};

export const changeEmployeeNameCase = (name) => {
	let splitName = name.toLowerCase().split(' ');
	for (let i = 0; i < splitName.length; i++) {
		splitName[i] = splitName[i].charAt(0).toUpperCase() + splitName[i].substring(1);
	}
	return splitName.join(' ');
};

export const formatHRCardCounts = (counts) => {
	let employeesCount = counts?.emiratesDocumentEmployeeCount || '';
	let expired = employeesCount?.expiredDocumentCount || 0;
	let expirySoonCount = employeesCount?.expirySoonCount || 0;
	let missingDateCount = employeesCount?.missingDateCount || 0;
	let totalEmployeesCount = employeesCount?.totalEmployeesCount || 0;
	let upToDateCount = employeesCount?.upToDateCount || 0;

	const cardItems = [
		{
			img: focus_state_icons.hrDocumentExpired,
			title: 'Expired Documents',
			value: totalEmployeesCount ? `${expired}/${totalEmployeesCount} Employees` : '',
			param: 'expired',
		},
		{
			img: focus_state_icons.hrDocumentWarning,
			title: 'Expiring Soon',
			value: totalEmployeesCount ? `${expirySoonCount}/${totalEmployeesCount} Employees` : '',
			param: 'warring',
		},
		{
			img: focus_state_icons.hrMissing,
			title: 'Missing Dates',
			value: totalEmployeesCount ? `${missingDateCount}/${totalEmployeesCount} Employees` : '',
			param: 'missing',
		},
		{
			img: focus_state_icons.hrDocumentUptoDate,
			title: 'Up To Date',
			value: totalEmployeesCount ? `${upToDateCount}/${totalEmployeesCount} Employees` : '',
			param: 'uptodate',
		},
	];

	return cardItems;
};

// HR Helpers
export const checkForMissingDate = (details) =>
	details && (!details?.labourCardExpiryDate || !details?.emiratesExpiryDate || !details?.visaExpiryDate || !details?.passportExpiryDate);

export const checkForExpired = (details, showdate) => {
	const labourCardExpiryDate = moment(details.labourCardExpiryDate);
	const emiratesExpiryDate = moment(details.emiratesExpiryDate);
	const visaExpiryDate = moment(details.visaExpiryDate);
	const passportExpiryDate = moment(details.passportExpiryDate);

	return (
		labourCardExpiryDate.format(DATE_FORMAT.YYYY_MM_DD) < showdate ||
		emiratesExpiryDate.format(DATE_FORMAT.YYYY_MM_DD) < showdate ||
		visaExpiryDate.format(DATE_FORMAT.YYYY_MM_DD) < showdate ||
		passportExpiryDate.format(DATE_FORMAT.YYYY_MM_DD) < showdate
	);
};

export const checkForUpToDate = (details, showdate) => {
	const labourCardExpiryDate = moment(details?.labourCardExpiryDate);
	const emiratesExpiryDate = moment(details?.emiratesExpiryDate);
	const visaExpiryDate = moment(details?.visaExpiryDate);
	const passportExpiryDate = moment(details?.passportExpiryDate);

	return (
		details?.labourCardExpiryDate &&
		details?.emiratesExpiryDate &&
		details?.visaExpiryDate &&
		details?.passportExpiryDate &&
		showdate < labourCardExpiryDate.subtract(6, 'month').format(DATE_FORMAT.YYYY_MM_DD) &&
		showdate < emiratesExpiryDate.subtract(6, 'month').format(DATE_FORMAT.YYYY_MM_DD) &&
		showdate < visaExpiryDate.subtract(6, 'month').format(DATE_FORMAT.YYYY_MM_DD) &&
		showdate < passportExpiryDate.subtract(6, 'month').format(DATE_FORMAT.YYYY_MM_DD)
	);
};

export const checkForExpirySoon = (details, showdate) => {
	return (
		(showdate >= moment(details.labourCardExpiryDate).subtract(6, 'month').format(DATE_FORMAT.YYYY_MM_DD) &&
			showdate <= details.labourCardExpiryDate) ||
		(showdate >= moment(details.emiratesExpiryDate).subtract(6, 'month').format(DATE_FORMAT.YYYY_MM_DD) &&
			showdate <= details.emiratesExpiryDate) ||
		(showdate >= moment(details.visaExpiryDate).subtract(6, 'month').format(DATE_FORMAT.YYYY_MM_DD) && showdate <= details.visaExpiryDate) ||
		(showdate >= moment(details.passportExpiryDate).subtract(6, 'month').format(DATE_FORMAT.YYYY_MM_DD) && showdate <= details.passportExpiryDate)
	);
};

export const checkForRules = (details, showdate) => {
	return [
		{
			name: 'uptodate',
			value: 'Up To Date',
			img: focus_state_icons.hrDocumentUptoDate,
			rulePass: checkForUpToDate(details, showdate),
		},
		{
			name: 'expired',
			value: 'Expired',
			img: focus_state_icons.hrDocumentExpired,
			rulePass: checkForExpired(details, showdate),
		},
		{
			name: 'expiresoon',
			value: 'Expiring Soon',
			img: focus_state_icons.hrDocumentWarning,
			rulePass: checkForExpirySoon(details, showdate),
		},
		{
			name: 'missingdate',
			value: 'Missing Dates',
			img: focus_state_icons.hrMissing,
			rulePass: checkForMissingDate(details, showdate),
		},
	];
};

// export const propsAreEqual = (prevState, nextState) => {
// 	const previousData = JSON.stringify(prevState);
// 	const nextData = JSON.stringify(nextState);
// 	return previousData === nextData;
// };

export const getBorderColor = (value, showdate) => {
	const momentValue = moment(value);
	return value == '' || value == null
		? '#484B52'
		: showdate < momentValue.subtract(6, 'month').format(DATE_FORMAT.YYYY_MM_DD)
			? '#dfe1e6'
			: value <= showdate
				? '#E60A14'
				: showdate >= momentValue.subtract(1, 'month').format(DATE_FORMAT.YYYY_MM_DD) ||
					showdate >= momentValue.subtract(3, 'month').format(DATE_FORMAT.YYYY_MM_DD) ||
					showdate >= momentValue.subtract(6, 'month').format(DATE_FORMAT.YYYY_MM_DD)
					? '#E4DD3D'
					: null;
};

export const getBorderClassColor = (value, showdate) => {
	const momentValue = moment(value);
	return value == '' || value == null
		? 'valid-date'
		: showdate < momentValue.subtract(6, 'month').format(DATE_FORMAT.YYYY_MM_DD)
			? 'default-date'
			: value < showdate
				? 'expired-date'
				: showdate >= momentValue.subtract(1, 'month').format(DATE_FORMAT.YYYY_MM_DD) ||
					showdate >= momentValue.subtract(3, 'month').format(DATE_FORMAT.YYYY_MM_DD) ||
					showdate >= momentValue.subtract(6, 'month').format(DATE_FORMAT.YYYY_MM_DD)
					? 'expired-soon'
					: null;
};

export const scrollToRef = (ref) => window.scrollTo(0, ref?.current?.offsetTop);

export const checkFile = (file, allowedFileTypes, callBackFunction) => {
	const format = file.name && file.name.split('.').splice(-1)[0];
	if (allowedFileTypes.indexOf(format) >= 0) {
		const isLt4M = file.size / 1024 / 1024 < 4;
		if (!isLt4M) {
			message.error(FILE_CONSTANTS.FILE_MUST_SMALLER_THAN_4MB);
		}
		return isLt4M;
	} else {
		message.error(FILE_CONSTANTS.PLEASE_UPLOAD_SUPPORTED_FORMATS);
		callBackFunction();
		return false;
	}
};

export const checkTwoArraysByLooping = (array1 = [], array2 = []) => {
	let modifiedData = (array1 || []).filter((d, index) => {
		let a = JSON.stringify(array1?.[index] || []);
		let b = JSON.stringify(array2?.[index] || []);
		return a !== b;
	});
	let isModified = !!modifiedData?.length || false;
	return [isModified, modifiedData || []];
};

// check date condition for request leave
export const checkForPassportDocuments = (passportExpiryDate, endDate, startDate, dontCalcSixMonth = false) => {
	return [
		{
			name: 'Expires',
			text: 'Valid',
			img: focus_state_icons.hrDocumentUptoDate,
			rulePass: checkPassportValid(passportExpiryDate, endDate, dontCalcSixMonth),
		},
		{
			name: 'Expired',
			text: 'Expired',
			img: focus_state_icons.hrDocumentExpired,
			rulePass: checkPassportExpired(passportExpiryDate, startDate, dontCalcSixMonth),
		},
		{
			name: 'Expiring',
			text: 'Expiring Soon',
			img: focus_state_icons.hrDocumentWarning,
			rulePass: checkPassportExpirySoon(passportExpiryDate, endDate, startDate, dontCalcSixMonth),
		},
		{
			name: 'Expiry Date',
			text: 'Missing Date',
			img: focus_state_icons.hrMissing,
			rulePass: checkPassportMissingDate(passportExpiryDate),
		},
	];
};
export const checkForVisaDocuments = (visaExpiryDate, endDate, startDate, dontCalcSixMonth = false) => {
	return [
		{
			name: 'Expires',
			text: 'Valid',
			img: focus_state_icons.hrDocumentUptoDate,
			rulePass: checkVisaValid(visaExpiryDate, endDate, dontCalcSixMonth),
		},
		{
			name: 'Expired',
			text: 'Expired',
			img: focus_state_icons.hrDocumentExpired,
			rulePass: checkVisaExpired(visaExpiryDate, startDate, dontCalcSixMonth),
		},
		{
			name: 'Expiring',
			text: 'Expiring Soon',
			img: focus_state_icons.hrDocumentWarning,
			rulePass: checkVisaExpirySoon(visaExpiryDate, endDate, startDate, dontCalcSixMonth),
		},
		{
			name: 'Expiry Date',
			text: 'Missing Date',
			img: focus_state_icons.hrMissing,
			rulePass: checkVisaMissingDate(visaExpiryDate),
		},
	];
};

export const checkPassportValid = (passportExpiryDate, endDate, dontCalcSixMonth) => {
	const passportDate = moment(passportExpiryDate).format(DATE_FORMAT.YYYY_MM_DD);
	const currentDate = endDate;
	const sixthMonthDate = dontCalcSixMonth ? currentDate : moment(currentDate).add(6, 'months').format('YYYY-MM-DD');
	const isValid = moment(passportDate).isAfter(sixthMonthDate) || moment(passportDate).isSame(sixthMonthDate);
	return isValid;
};
export const checkPassportExpirySoon = (passportExpiryDate, endDate, startDate, dontCalcSixMonth) => {
	const passportDate = moment(passportExpiryDate).format(DATE_FORMAT.YYYY_MM_DD);
	const currentDate = endDate;
	const sixthMonthDate = dontCalcSixMonth ? currentDate : moment(currentDate).add(6, 'months').format('YYYY-MM-DD');
	const isExpiringSoon =
		moment(endDate).isAfter(startDate) || moment(startDate).isSame(endDate)
			? (moment(passportDate).isAfter(currentDate) && moment(passportDate).isBefore(sixthMonthDate)) ||
			(moment(passportDate).isAfter(startDate) && moment(passportDate).isBefore(endDate)) ||
			moment(passportDate).isSame(endDate)
			: null;

	return isExpiringSoon;
};
export const checkPassportExpired = (passportExpiryDate, endDate, dontCalcSixMonth) => {
	const passportDate = moment(passportExpiryDate).format(DATE_FORMAT.YYYY_MM_DD);
	const currentDate = endDate;
	const isExpired = dontCalcSixMonth ? null : moment(passportDate).isBefore(currentDate);
	return isExpired;
};

export const checkPassportMissingDate = (passportExpiryDate, employeeData) => {
	return !passportExpiryDate;
};
export const checkVisaValid = (visaExpiryDate, endDate, dontCalcSixMonth = false) => {
	const passportDate = moment(visaExpiryDate).format(DATE_FORMAT.YYYY_MM_DD);
	const currentDate = endDate;
	const sixthMonthDate = dontCalcSixMonth ? currentDate : moment(currentDate).add(6, 'months').format('YYYY-MM-DD');
	const isValid = moment(passportDate).isAfter(sixthMonthDate) || moment(passportDate).isSame(sixthMonthDate);
	return isValid;
};
export const checkVisaExpirySoon = (visaExpiryDate, endDate, startDate, dontCalcSixMonth) => {
	const passportDate = moment(visaExpiryDate).format(DATE_FORMAT.YYYY_MM_DD);
	const currentDate = endDate;
	const sixthMonthDate = dontCalcSixMonth ? currentDate : moment(currentDate).add(6, 'months').format('YYYY-MM-DD');
	const isExpiringSoon =
		moment(endDate).isAfter(startDate) || moment(startDate).isSame(endDate)
			? (moment(passportDate).isAfter(currentDate) && moment(passportDate).isBefore(sixthMonthDate)) ||
			(moment(passportDate).isAfter(startDate) && moment(passportDate).isBefore(endDate)) ||
			moment(passportDate).isSame(endDate)
			: null;
	return isExpiringSoon;
};
export const checkVisaExpired = (visaExpiryDate, endDate, dontCalcSixMonth) => {
	const passportDate = moment(visaExpiryDate).format(DATE_FORMAT.YYYY_MM_DD);
	const currentDate = endDate;
	const isExpired = dontCalcSixMonth ? null : moment(passportDate).isBefore(currentDate);
	return isExpired;
};

export const checkVisaMissingDate = (visaExpiryDate, employeeData) => {
	return !visaExpiryDate;
};

// export const checkForVisaDocuments = (visaExpiryDate, endDate) => {
// 	return [
// 		{
// 			name: 'Valid',
// 			text: 'Valid',
// 			img: images.hR_upToDate,
// 			rulePass: checkVisaValid(visaExpiryDate, endDate),
// 		},
// 		{
// 			name: 'Expired',
// 			text: 'Expired',
// 			img: images.hR_expired,
// 			rulePass: checkVisaExpired(visaExpiryDate, endDate),
// 		},
// 		{
// 			name: 'ExpireSoon',
// 			text: 'Expiring Soon',
// 			img: images.hR_warning,
// 			rulePass: checkVisaExpirySoon(visaExpiryDate, endDate),
// 		},
// 		{
// 			name: 'MissingDate',
// 			text: 'Missing Date',
// 			img: images.hR_missing,
// 			rulePass: checkVisaMissingDate(visaExpiryDate),
// 		},
// 	];
// };

//
// Notification helper
export const openNotificationWithIcon = (
	type,
	message = NOTIFICATION_TYPE.WARNING,
	description = '',
	placement = NOTIFICATION_POSITION.TOP_RIGHT
) => {
	notification[type]({
		message,
		description,
		placement,
	});
};
export const debounceHandler = debounce((query, functionToCall, param = '') => {
	functionToCall(query, param);
}, 800);
// with more flexible and more props
export const debounceHandlerWithMoreProps = debounce((query, functionToCall, ...props) => {
	functionToCall(query, ...props);
}, 800);

// getObject from Array
export const getObjectFromArray = (array = [], keyName = '', value = '') => {
	return Object.assign({}, ...array?.filter((obj) => obj[keyName] === value));
};
export let myObject = {
	id: 1,
	name: 'Ali',
	address: null,
	phone: null,
	age: 22,
};
Object.keys(myObject).map(function (key, index) {
	if (myObject[key] == null) {
		myObject[key] = '';
	}
});

//remove null from object
export const removeNullFromObject = (obj = {}) => {
	Object.keys(obj).map(function (key, index) {
		if (obj[key] == null) {
			obj[key] = '';
		}
	});
	return obj;
};

export const buttonDisable = (payrollDetails, addAllowance, employeeContracts, isEnabled) => {
	const startDate = moment(payrollDetails?.joiningDate).format('YYYY-DD-MM'); //Change the date formate as per API Formate.
	const endDate =
		payrollDetails?.contractTypeId === 2 || payrollDetails?.endDate === '' ? null : moment(payrollDetails?.endDate).format('YYYY-MM-DD');
	const basicSalary = payrollDetails?.salary != '' ? parseFloat(amountWithNoCommas(payrollDetails?.salary)) : null;
	const datepick =
		payrollDetails?.endDate != '' &&
		moment(payrollDetails?.joiningDate).format('YYYY-MM-DD') > moment(payrollDetails?.endDate).format('YYYY-MM-DD');
	const response1 = addAllowance?.map((data) => {
		return {
			id: data?.id,
			allowanceTypeId: data?.allowanceId,
			allowanceTypeName: data?.allowance,
			value: parseFloat(amountWithNoCommas(data?.aed)),
		};
	});
	const nObject = {
		contractTypeId: employeeContracts?.contractTypeId,
		departmentId: employeeContracts?.departmentId,
		designation: employeeContracts?.designation,
		employeeAllowances: employeeContracts?.employeeAllowances,
		employeeBasicSalary: employeeContracts?.employeeBasicSalary != null ? employeeContracts?.employeeBasicSalary?.value : null,
		employeeId: employeeContracts?.employeeId,
		endDate: employeeContracts?.endDate != null ? moment(employeeContracts?.endDate).format('YYYY-MM-DD') : null,
		id: employeeContracts?.id,
		startDate: moment(employeeContracts?.startDate).format('YYYY-MM-DD'),
	};
	const nObject1 = {
		contractTypeId: payrollDetails?.contractTypeId,
		departmentId: payrollDetails?.departmentId,
		designation: payrollDetails?.designation,
		employeeAllowances: response1,
		employeeBasicSalary: basicSalary,
		employeeId: employeeContracts?.employeeId,
		endDate: endDate,
		id: payrollDetails?.id,
		startDate: startDate,
	};
	return (
		JSON.stringify(nObject) === JSON.stringify(nObject1) ||
		!payrollDetails?.joiningDate ||
		!payrollDetails?.department ||
		!payrollDetails?.designation ||
		!payrollDetails?.salary ||
		(!isEnabled?.UnLimited && !isEnabled?.Limited) ||
		(isEnabled?.Limited && datepick) ||
		(isEnabled?.Limited && !payrollDetails?.endDate) ||
		parseInt(payrollDetails?.salary) === 0
	);
};
export const forEnablingButton = (employeeDetails, employeeDetailState) => {
	return (
		employeeDetailState?.nationality === employeeDetails?.nationality &&
		employeeDetailState?.dob === employeeDetails?.dateOfBirth &&
		employeeDetailState?.emailId === employeeDetails?.emailId &&
		employeeDetailState?.wpsPersonid === employeeDetails?.wpsPersonid &&
		employeeDetailState?.wpsEstablishmentId === employeeDetails?.wpsEstablishmentId &&
		employeeDetailState?.iban === employeeDetails?.bankAccountNo &&
		employeeDetailState?.bankName === employeeDetails?.bankRoutingCode &&
		employeeDetailState?.labourCardNo === employeeDetails?.labourCardNo
	);
};

export const findObjFromArray = (array = [], key, fieldToBeChecked) => {
	const obj = array.find((data) => data[fieldToBeChecked] === key);
	return obj;
};
export const separator = (number = 0) => {
	var str = number.toString().split('.');
	str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	return str.join('.');
};
export const approvalButtonDiable = (approvalWorkflowDetails, payload) => {
	return JSON.stringify(approvalWorkflowDetails) === JSON.stringify(payload);
};
export const formatMenus = (menus, role) => {
	const nRole = role?.map((data) => data?.name);
	return menus
		?.map((menu) => {
			const { roles = [] } = menu;
			// if (nRole.includes(roles)) {
			menu = {
				...menu,
				subMenus: (menu?.subMenus || [])?.filter((submenu) => {
					const { roles = [] } = submenu;
					// if (nRole?.includes(roles)) {
					return submenu;
					// }
				}),
			};
			return menu;
			// }
		})
		.filter((menu) => menu);
};

export const amountWithCommas = (value) => {
	return value
		?.toString()
		.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
		.split(',')
		.join('')
		.toString()
		.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
		.replace(/[^0-9.,]/g, '');
};
export const amountWithNoCommas = (value) => {
	return value && value?.toString().replace(/,/g, '');
};
export const twoDigitAfterDec = (value) => {
	const slited = value?.split('.');
	if (value.includes('.')) {
		return slited?.[0] + '.' + slited?.[1]?.slice(0, 2);
	} else {
		return value;
	}
};
export const onlyNumberForNumberType = (evt) => {
	return (
		(evt.key === '+' && evt.preventDefault()) ||
		(evt.key === 'E' && evt.preventDefault()) ||
		(evt.key === 'e' && evt.preventDefault()) ||
		(evt.key === '-' && evt.preventDefault())
	);
};
export const onlyNumberForTextType = (number) => {
	return number && number.replace(/[^0-9]/g, '');
};

/**
 * To Calculate the difference of two dates in total days
 */
export function getDaysCountByTwoDates(startDate, endDate) {
	// Month value starts from 0 (Jan month number is 0), Syntax Date(year, month, date)
	startDate = new Date(); //Current Date
	endDate = new Date(2023, 9, 1); //01 Oct 2023

	const startDateVal = startDate.valueOf();
	const endDateVal = endDate.valueOf();

	let noOfdays = (endDateVal - startDateVal) / 1000 / 86400;
	noOfdays = Math.round(noOfdays - 0.5);
	if (noOfdays >= 0) {
		return noOfdays;
	} else {
		return 0;
	}
}

export const payrollDiasble = (gratuity, payRollRun, saveValue) => {
	const { additions, deductions, employeeAllowances, employeeContract, overtimes } = payRollRun;
	const nAdd = additions?.map((data) => {
		return {
			id: data?.id,
			additionTypeId: data?.additionTypeId,
			value: parseFloat(data?.value),
		};
	});
	const nDed = deductions?.map((data) => {
		return {
			id: data?.id,
			deductionTypeId: data?.deductionTypeId,
			value: parseFloat(data?.value),
		};
	});
	const nAllow = employeeAllowances?.map((data) => {
		return {
			id: data?.id,
			allowanceTypeId: data?.allowanceTypeId,
			value: parseFloat(data?.value),
		};
	});
	const nPol = overtimes?.map((data) => {
		return {
			id: data?.id,
			overtimePolicyId: data?.overtimePolicyId,
			hours: parseFloat(data?.hours),
		};
	});
	const obj = {
		allowanceList: nAllow,
		overtimePayList: nPol,
		additionList: nAdd,
		deductionList: nDed,
		leavingDate: employeeContract?.leavingDate,
	};
	return JSON.stringify(obj) === JSON.stringify(saveValue);
};
export const forDisableButton = (array1, array2) => {
	return JSON.stringify(array1) === JSON.stringify(array2);
};

export const emailCheck =
	/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isEmailValid = (email) => {
	return email && email.match(emailCheck);
};
export const isOnlySpecialCharacter = (string) => {
	const reg = /^([a-zA-Z0-9]+)$/;
	return string && reg?.test(string);
};
export const entriesDropDown = [
	{
		id: 1,
		value: 10,
	},
	{
		id: 2,
		value: 20,
	},
	{
		id: 3,
		value: 30,
	},
	{
		id: 4,
		value: 40,
	},
	{
		id: 5,
		value: 50,
	},
];

export const templateDownload = (file, filename = '') => {
	const downloadUrl = window.URL.createObjectURL(file);
	const link = document.createElement('a');
	link.href = downloadUrl;
	link.setAttribute('download', filename);
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};

export const arrayObjToArray = (array, key) => {
	return array?.map((obj) => obj?.[key]);
};

export const arrayToObject = (array = [], value = '', objKey = '', withKey = false) => {
	var obj = {};
	if (value && objKey && withKey) {
		array.forEach((elem, i) => {
			obj[`${objKey} ${withKey ? i + 1 : ''}`] = elem?.[value];
		});
	} else {
		array.forEach((elem, i) => {
			obj[i + 1] = elem;
		});
	}
	return obj;
};
export const getObjectLength = (obj = {}) => {
	return Object?.keys(obj)?.length;
};

export const checkRoles = (userClaims = [], claim) => userClaims?.find((claimData) => claimData?.name === claim);

const colorCodes = {
	pending: '#FFDC91',
	partiallyfailed: '#FFBECB',
	done: '#8EF9B3',
	failed: '#FF9691',
};

// Payroll Status
export const getCardStatusColor = (status) => {
	status = status ? status.replace(/ +/g, '').toLowerCase() : 'pending';
	return {
		color: colorCodes[status],
	};
};

// Employee Card Status
export const getCardStatus = (status) => {
	if (status?.toLowerCase()?.includes('partially')) {
		return {
			status: 'Partially Failed',
			color: '#FFBECB',
		};
	} else if (status?.toLowerCase()?.includes('delivered')) {
		return {
			status: 'Delivered',
			color: '#8EF9B3',
		};
	} else if (status?.toLowerCase()?.includes('failed')) {
		return {
			status: 'Failed',
			color: '#FF9691',
		};
	} else {
		return {
			status: 'Pending',
			color: '#FFDC91',
		};
	}
};

export const formatDateFromInput = (input = new Date(), format = '') => moment(input).format(format)

// Used to logout the user from portal
export const userLogout = () => {
	userManager.signoutRedirect({ id_token_hint: localStorage.getItem(APP_VARIABLES.ID_TOKEN) });
	userManager.removeUser();
	localStorage.clear()
}

/**
 * @param {Number} amount
 * The amount we used to return with decimal digits.
 * @param {Number} decimalCount
 * By default the decimal count is 2, you can add the count to override the default.
 * @param {Intl.LocalesArgument} locales
 * The Locales to use eg: en-US (US locale), de-DE (German locale) by default undefined.
 * @returns {String} The amount in locale string with the given decimal count.
 */
export const amountWithDecimalsCount = (amount = 0, decimalCount = 2, locales = undefined) => Number(amount).toLocaleString(locales, { maximumFractionDigits: decimalCount })


export { callCleverTapEvent }
